<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form :inline="true" size="mini" :model="search">
          <el-form-item label="开卡日期">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w300"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchTime"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入店铺名称"
              class="w120 mr20"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>

		<el-form-item label="开卡名称" v-if="!clienId">
			  <el-input
				v-model="search.name"
				maxlength="30"
				class="w120 mr20"
				placeholder="请输入"
				clearable
			  />
		</el-form-item>
          <el-form-item label="姓名" v-if="!clienId">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="电话" v-if="!clienId">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="有效期">
            <el-select v-model="search.effective" placeholder="请选择" clearable>
              <el-option
                v-for="item in options.effective"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>


        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <!-- <el-button type="primary" icon="el-icon-search" size="mini" @click="exportData('export')">导出</el-button> -->
      </el-col>
    </el-row>


    <!--列表-->
    <el-row class="mt30">
      <div class="btnBox">
        <el-button
          type="primary"
          class="mb10"
          size="mini"

          @click="exportData('export')"
        >导出数据</el-button>
      </div>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="开卡日期" prop="create_time" width="90" />
          <ElTableColumn label="顾客姓名" prop="customer_name" />
          <ElTableColumn label="客户手机号" prop="telephone" width="100"/>
          <ElTableColumn label="来源" prop="source_name" />
          <ElTableColumn label="开卡人" prop="kk_user_name" />
          <ElTableColumn label="开卡部门" prop="shop_name" />
          <ElTableColumn label="开卡分类" prop="category_name" />
          <ElTableColumn label="开卡名称" prop="name" width="150"/>
          <ElTableColumn label="卡项别名" prop="goods_alias_name" width="140" />
          <ElTableColumn label="最新到店日期" prop="last_shop_time" />
          <ElTableColumn label="开卡备注" prop="description" />
          <ElTableColumn label="开卡金额" prop="kk_money" />
          <ElTableColumn label="消耗本金" prop="consume_money" />
          <ElTableColumn label="消耗赠金" prop="consume_gratuity" />
          <ElTableColumn label="消耗店转" prop="consume_store_transfer" />
          <ElTableColumn label="月核销次" prop="service_max_num" />
          <ElTableColumn label="开卡次数">
            <template slot-scope="{ row }">
              <span
              v-if="row.service_type == 2"
              @click="changeNum(row)"
              >
              {{ row.num }}
              </span>
              <span v-else>{{ row.num }}</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="剩余次数" prop="yy_num" />
          <ElTableColumn label="有效期" width="260">
            <template slot-scope="{ row }">
              <span
              v-if="row.service_type == 1"
              @click="changeDate(row)"
              >
              {{ row.start_time }}-{{row.end_time}}
              </span>
              <span v-else>无限制</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="消耗次数" prop="sy_num" />
          <ElTableColumn label="操 作" fixed="right" width="160">
            <template slot-scope="{ row }">
              <span class="theme" @click="tapDetail(row.id)">查 看</span>
              <span
                v-if="row.status == 1 && clienId "
                class="theme ml20"
                @click="verification(row.id)"
              >核 销</span>
              <span
                v-if="row.status == 1 && clienId"
                class="theme ml20"
                @click="hint(row.id,'此客户服务将失效')"
              >失 效</span>
            </template>
          </ElTableColumn>
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 调整弹出 -->
    <el-dialog title="次卡调整" :visible.sync="changeNumPop" width="40%">
      <el-form
        :inline="true"
        ref="changeData"
        size="mini"
        :model="changeData"
        label-width="150px"
      >
        <el-form-item label="调整开卡次数" prop="num">
          <el-input-number class="w300" v-model="changeData.num" :min="-10000" :precision="0" ></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="changeNumPop = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitFormC('changeData')">确 定</el-button>
      </span>

    </el-dialog>


    <el-dialog title="月卡调整" :visible.sync="changeDatePop" width="40%">
      <el-form
        :inline="true"
        ref="changeData"
        size="mini"
        :rules="rules"
        :model="changeData"
        label-width="150px"
      >
        <el-form-item label="调整卡项到期时间" prop="end_time">

          <el-date-picker
              v-model="changeData.end_time"
              type="date"
              class="w300"
              placeholder="请选择调整卡项到期时间"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="changeDatePop = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitFormC('changeData')">确 定</el-button>
      </span>

    </el-dialog>

    <!-- 核销弹出 -->
    <el-dialog title="核销处理" :visible.sync="verificationPop" width="40%">
      <el-form
        :inline="true"
        ref="verifyDetail"
        size="mini"
        :rules="rules"
        :model="verifyDetail"
        label-width="90px"
      >
        <el-form-item label="服务次数" prop="num">
          <el-input
            v-model="verifyDetail.num"
            maxlength="30"
            class="w200"
            placeholder="请输入服务次数"
            clearable
          />
        </el-form-item>
        <el-form-item label="护理师1" prop="nurse_id_one">
          <el-select
            v-model="verifyDetail.nurse_id_one"
            placeholder="请选择"
            class="w200"
            @change="changeStaff"
          >
            <el-option
              v-for="item in options.sale_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label=" 护理师2">
          <el-select
            v-model="verifyDetail.nurse_id_two"
            placeholder="请选择"
            class="w200"
            @change="changeStaff"
          >
            <el-option
              v-for="item in options.sale_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="verificationPop = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm('verifyDetail')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { serviceList, addServiceLog, gbservice,changeService } from "@/api/client";
import { staffList } from "@/api/staff";
import { getShopList } from "@/api/shop";
class Search {
  start_time = ""; // 开始日期
  endTime = ""; // 结束日期
  customer_name = ""; // 客户名称
  telephone = ""; // 客户电话
  effective = "";
  shop_id = "";
  name = ""; //开卡名称
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class VerifyDetail {
  service_id = "";
  nurse_id_one = "";
  nurse_id_two = "";
  num = "";
  start_time = "";
  end_time = "";
}

class changeData {
  service_id = "";
  num = "";
  end_time = "";
}

class Rules {
  nurse_id_one = [
    { required: true, message: "请选择护理师", trigger: "change" }
  ];
  num = [{ required: true, message: "请输入服务次数", trigger: "blur" }];
}

class Options {
  constructor() {
    this.shop_id = []; // 店铺
  }
  sale_id = [];
  effective = [
    { id: 15, name: "15天" },
    { id: 30, name: "30天" },
    { id: 45, name: "45天" },
    { id: 60, name: "60天" }
  ];
}



export default {
  name: "ServeRecord",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(),
      verifyDetail: new VerifyDetail(),
      changeData: new changeData(),
      options: new Options(),
      rules: new Rules(),
      page: new Page(), // 分页
      list: [], // 数据展示
      verificationPop: false, //核销弹出
      changeNumPop: false, //调整弹出_次卡
      changeDatePop: false, //调整弹出_月卡
      timeValue: [],
      value1: [],
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      }
    };
  },

  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      serviceList({
        ...this.search,
        customer_id: this.clienId,
        ...this.page
      }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
      this.getStaff();
    },

    // 导出
    exportData(option){
      serviceList({
        ...this.search,
        export : 1
      }).then(res => {
        window.open(res.url);
      });
      this.getStaff();
    },

    // 客户所在店铺员工列表
    getStaff() {
      staffList({ page: 1, limit: 1000000, shopId: this.detail.shopid_U }).then(
        res => {
          this.options.sale_id = res.list;
        }
      );
    },
    // 重复护理师提示
    changeStaff() {
      if (this.verifyDetail.nurse_id_one == this.verifyDetail.nurse_id_two) {
        this.$message({
          type: "warning",
          message: "请选择不同护理师"
        });
        this.verifyDetail.nurse_id_two = "";
      }
    },
    // 开卡日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.endTime = val[1];
      } else {
        this.search.start_time = "";
        this.search.endTime = "";
        this.getList("search");
      }
    },
    // 点击详情
    tapDetail(id) {
      const query = {
        id: id,
        types: "second",
        clienId: this.clienId,
        detail: this.detail
      };
      // this.$router.push({ name: "客户服务详情", query });
      let route = this.$router.resolve({ name: "客户服务详情", query });
      window.open(route.href, '_blank');
    },

    // 点击调整次数
    changeNum(date){
      this.changeNumPop = true;
      this.changeData = new changeData();

      this.changeData.service_id = date.id;
      this.changeData.shop_id = date.id;
      this.changeData.old_num = date.num;
      this.changeData.service_type =  date.service_type;
    },
    // 点击调整日期
    changeDate(date){
      this.changeDatePop = true;
      this.changeData = new changeData();

      this.changeData.service_id = date.id;
      this.changeData.shop_id = date.id;
      this.changeData.old_time = date.end_time;
      this.changeData.service_type =  date.service_type;
    },
    // 点击核销
    verification(id) {
      this.verificationPop = true;
      this.verifyDetail = new VerifyDetail();
      this.verifyDetail.service_id = id;
    },
    // 选择时间
    changeTime(e) {
      this.verifyDetail.start_time = e[0];
      this.verifyDetail.end_time = e[1];
    },
    // 提交核销
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("use", "核销此客户服务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 调整提交
    submitFormC(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("change", "调整此客户服务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {

          if (type == "use") {
            this.confirm();
          } else if(type == "change"){
            this.changeServeFunction();
          } else {
            this.nullify(type);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 点击失效
    nullify(id) {
      gbservice({ service_id: id }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getList();
      });
    },
    // 确定核销
    confirm() {
      addServiceLog({
        ...this.verifyDetail,
        shop_id: this.detail.shop_id,
        customer_id: this.clienId
      }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.verificationPop = false;
        this.getList();
      });
    },
    // 确定调整
    changeServeFunction() {
      // console.log(changeData);
      changeService({
        ...this.changeData,

        shop_id: this.detail.shop_id,
        customer_id: this.clienId
      }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.changeDatePop = false;
        // this.getList();
      });
    },

    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
